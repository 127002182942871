import React from "react"
import { css, jsx } from "@emotion/core"

import SiteLayout from "./siteLayout.js"
import SidebarWrapper from "../sidebar/sidebarWrapper.js"
import MainWrapper from "../sidebar/mainWrapper.js"
import QuickLinkMenu from "../sidebar/quickLinkMenu.js"
import MemberDirectoryLink from "../sidebar/memberDirectoryLink.js"
import UpcomingEvents from "../sidebar/upcomingEvents.js"

class AboutLayout extends React.Component {
  render() {
    return (
      <SiteLayout>
        <div
          css={{
            position: "relative",
          }}
        >
          <div css={{ padding: "0px 25px" }}>
            <h1>{this.props.pageTitle}</h1>
          </div>
          <MainWrapper>{this.props.children}</MainWrapper>
          <SidebarWrapper>
            <QuickLinkMenu
              title="Industry Quick Links"
              links={[
                {
                  to: "/industry/membership-directory",
                  text: "Membership Directory",
                },
                {
                  to: "/industry/downtown-development-authority",
                  text: "Downtown Development",
                },
                {
                  to: "/industry/ecconomic-development-authority",
                  text: "Economic Development",
                },
              ]}
            />
            <MemberDirectoryLink />
            <UpcomingEvents />
          </SidebarWrapper>
        </div>
      </SiteLayout>
    )
  }
}
export default AboutLayout
