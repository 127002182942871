import React from "react"
import { css, jsx } from "@emotion/core"

import IndustryLayout from "../../components/layouts/industryLayout"
import CDDA from "../../images/cdda.png"

const DowntownDevelopmentAuthorityPage = props => (
  <IndustryLayout pageTitle="Claxton Downtown Development Authority">
    <div>
    <img
        src={CDDA}
        css={{
          display: "inline-block",
          border: "1px solid #000",
          width: "100%",
          float: "left",
          marginRight: "1rem",
          marginBottom: `1rem`,
          "@media (min-width: 800px)": {
            width: `45%`,
            marginBottom: `0`,
          },
        }}
      />
      <p>
        The Claxton Downtown Development Authority (DDA) was formed in August
        1982. It is the goal of the DDA to work with city officials and other
        groups and authorities to revitalize downtown Claxton by assisting
        existing businesses with expansion projects, and bringing new retail and
        tourism opportunities to Claxton.
        <br />
        <br />
      </p>
      <p>
        In 2008, the DDA initiated a Façade Improvement Program to help redevelop the
        business district, promote new business, and enhance the appearance of
        the downtown area. Façade Improvement Program funds are available for exterior
        improvements such as painting or replacing windows and awnings. This is
        a “matching grant” program. The DDA will reimburse the business
        owner/property owner, 50% of the cost of an approved renovation project
        up to $1500. Since its inception, over $27,000 has been awarded to local
        businesses. <br />
        <br />
      </p>
      <p>
        The DDA sponsors events each year to increase activity in Claxton and
        bring awareness to the downtown area. In addition, the DDA works closely
        with city officials on beautification projects to include the American
        flag display in downtown Claxton. <br />
        <br />
      </p>
      <p>
        The Claxton Downtown Development Authority is a seven member volunteer
        board appointed by the City of Claxton. The DDA meets on the third
        Tuesday of each month, 10:00 a.m. at Claxton City Hall. <br />
        <br />
      </p>
    </div>
  </IndustryLayout>
)

export default DowntownDevelopmentAuthorityPage
